<!-- Announcements -->
<ng-container *ngIf="criticalAnnouncements$ | async as criticalAnnouncements">
    <div *ngIf="criticalAnnouncements.length">
        <app-announcement
            *ngFor="let announcement of criticalAnnouncements"
            [announcement]="announcement"
            (onDismiss)="dismiss(announcement)"
        ></app-announcement>
    </div>
</ng-container>
