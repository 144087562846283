import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../modules/shared/shared.module";
import { TourMatMenuModule } from "ngx-ui-tour-md-menu";
import { MatTooltipModule } from "@angular/material/tooltip";

// Forms
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";

// Components
import { DashboardComponent } from "./dashboard.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        FontAwesome,
        SharedModule,
        TourMatMenuModule,
        MatTooltipModule
    ],
    declarations: [DashboardComponent]
})
export class DashboardModule {}
