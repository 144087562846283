import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { LiveEventsService } from "../../../live-events.service";
import { take } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class EventsListResolverService implements Resolve<unknown[]> {
    constructor(private es: LiveEventsService) {}

    resolve(): Promise<unknown[]> | Promise<never> {
        const promises: Promise<unknown>[] = [];
        promises.push(this.es.refreshLiveEvents(true).pipe(take(1)).toPromise());
        return Promise.all(promises);
    }
}
