import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/guards/auth.guard";
import { LiveEventFormComponent } from "./pages/live-event-form/live-event-form.component";
import { LiveEventDetailsComponent } from "./pages/event-details/live-event-details.component";
import { LiveEventsListComponent } from "./pages/events/live-events-list/live-events-list.component";
import { EventsListResolverService } from "./pages/events/live-events-list/live-events-list-resolver.service";
import { Constants } from "src/app/constants/constants";

const routes: Routes = [
    {
        path: Constants.urls.liveevents + "/new",
        canActivate: [AuthGuard],
        component: LiveEventFormComponent,
        data: {
            help: [
                {
                    menu: "HELP_MENU.NEW_LIVE_EVENT",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/EQDTHAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.liveevents + "/:id/edit",
        canActivate: [AuthGuard],
        component: LiveEventFormComponent,
        resolve: {
            events: EventsListResolverService
        }
    },
    {
        path: Constants.urls.liveevents + "/:id/clone",
        canActivate: [AuthGuard],
        component: LiveEventFormComponent,
        resolve: {
            events: EventsListResolverService
        }
    },
    {
        path: Constants.urls.liveevents,
        canActivate: [AuthGuard],
        component: LiveEventsListComponent,
        resolve: {
            events: EventsListResolverService
        },
        data: {
            help: [
                {
                    menu: "HELP_MENU.LIVE_EVENTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/BQDTHAE"
                },
                {
                    menu: "HELP_MENU.NEW_LIVE_EVENT",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/EQDTHAE"
                },
                {
                    menu: "HELP_MENU.VIEWING_EXISTING_EVENTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/YADQHAE"
                }
            ]
        }
    },
    {
        path: Constants.urls.liveevents + "/:id",
        canActivate: [AuthGuard],
        component: LiveEventDetailsComponent,
        data: {
            help: [
                {
                    menu: "HELP_MENU.VIEWING_EVENT_DETAILS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/UgDNHAE"
                },
                {
                    menu: "HELP_MENU.MANUAL_CONTROLS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/NIDQHAE"
                }
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LiveEventsRoutingModule {}
