import { Component, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

import { SearchService } from "../../services/search.service";
import { TargetsService } from "../targets/targets.service";
import { SearchResult } from "../../models/search";

import { Constants } from "../../constants/constants";
import { SomeZixiObject } from "src/app/models/shared";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../services/title.service";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    templateUrl: "./search.component.html"
})
export class SearchComponent implements OnDestroy {
    loading = true;
    result: SearchResult;
    private searchFilter: string;
    private searchSubscription: Subscription;

    resultTitles = {
        feeders: "FEEDERS",
        clusters: "CLUSTERS",
        receivers: "RECEIVERS",
        remote_access: "REMOTE_ACCESS",
        channels: "CHANNELS",
        targets: "TARGETS",
        sources: "SOURCES"
    };

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private searchService: SearchService,
        private targetsService: TargetsService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("SEARCH"));
        // The ActivatedRoute dies with the routed component and so the subscription dies with it.
        this.searchSubscription = this.route.queryParamMap.subscribe(params => {
            this.searchVal = params.get("q");
            this.search();
        });
    }

    set searchVal(v: string) {
        this.searchFilter = v;

        if (this.searchFilter.length >= 3) this.search();
    }
    get searchVal(): string {
        return this.searchFilter;
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }

    search() {
        this.loading = true;
        this.searchService.search(this.searchVal.trim()).then((result: SearchResult) => {
            this.result = result;
            this.loading = false;
        });
    }

    isEmptyObject(obj: unknown) {
        return obj && Object.keys(obj).length === 0;
    }

    goto(type: string, object: SomeZixiObject) {
        switch (type) {
            case "zecs":
                this.router.navigate(urlBuilder.getRegularZecUrl(object.id, Constants.urls.zecs, object.name));
                break;
            case "feeders":
                this.router.navigate(urlBuilder.getRegularZecUrl(object.id, Constants.urls.feeders, object.name));
                break;
            case "clusters":
                this.router.navigate(urlBuilder.getRegularClusterUrl(object.id, object.name));
                break;
            case "receivers":
                this.router.navigate(urlBuilder.getRegularZecUrl(object.id, Constants.urls.receivers, object.name));
                break;
            case "remote_access":
                this.router.navigate([Constants.urls.remote_access, object.name]);
                break;
            case "channels":
                this.router.navigate(
                    urlBuilder.getRegularChannelUrl(
                        object.id,
                        object.type === "delivery" ? "pass-through" : object.type ? object.type : "mediaconnect",
                        object.name
                    )
                );
                break;
            case "targets":
                this.router.navigate(
                    urlBuilder.getRegularTargetUrl(object.id, this.targetsService.hackAPIType(object), object.name)
                );
                break;
            case "sources":
                if (object.whitelist_cidr != null && object.whitelist_cidr !== "")
                    this.router.navigate([Constants.urls.sources, "mediaconnect", object.name]);
                else this.router.navigate(urlBuilder.getRegularSourceUrl(object.id, object.name));
                break;
        }
    }
}
