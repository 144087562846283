<div class="page-container">
    <div class="page-content">
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 translate title="{{ 'SEARCH' | translate }}">SEARCH</h1>
            </div>
        </div>
        <div class="content-area">
            <div class="container-fluid">
                <!-- Search -->
                <div class="row">
                    <div class="col-12">
                        <div class="input-group mb-3">
                            <input
                                type="text"
                                class="form-control"
                                placeholder="{{ 'SEARCH' | translate }}..."
                                [(ngModel)]="searchVal"
                                aria-label="Search"
                                aria-describedby="search-button"
                                (keyup)="$event.keyCode === 13 && search()"
                            />
                            <button class="btn btn-outline-primary" type="button" id="search-button" (click)="search()" [disabled]="!searchVal">
                                <fa-icon class="me-1" icon="search" size="sm"></fa-icon>{{ "SEARCH" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="loading">
                    <!-- Loading -->
                    <div class="col-12">
                        <content-loading-animation class="block"></content-loading-animation>
                    </div>
                </div>
                <!-- Search Results -->
                <div class="details-content-accordions">
                    <div class="row" *ngIf="!loading && isEmptyObject(result)">
                        <div class="col-12">
                            {{ "NO_RESULTS" | translate }}
                        </div>
                    </div>

                    <div class="row" *ngIf="!loading && !isEmptyObject(result)">
                        <!-- Status -->
                        <div class="col-12 col-xxl-6" *ngFor="let item of result | keyvalue">
                            <ngb-accordion #detailsAccordion="ngbAccordion" activeIds="ngb-panel-details" class="no-padding">
                                <ngb-panel id="ngb-panel-details">
                                    <ng-template ngbPanelTitle>
                                        <h3 translate title="{{ resultTitles[item.key] | translate }}">{{ resultTitles[item.key] }}</h3>
                                        <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                                        <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <table class="table table-sm mb-0">
                                            <tbody>
                                                <tr *ngFor="let object of item.value">
                                                    <td>
                                                        <zx-status-full [model]="object"></zx-status-full>
                                                    </td>
                                                    <td>{{ object.name }}</td>
                                                    <td class="text-end">
                                                        <a href="javascript:void(0)" title="{{ 'VIEW' | translate }}" (click)="goto(item.key, object)"
                                                            ><span>{{ "VIEW" | translate }}</span></a
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
