<!-- Size Detector -->
<app-size-detector></app-size-detector>
<!-- UI Tour -->
<zx-ui-tour></zx-ui-tour>
<!-- Main App Loader -->
<div class="app-loading" *ngIf="!translationLoaded">
    <div class="logo">
        <img width="200" alt="Zixi logo" src="assets/images/zixi-white.png" />
    </div>
    <div class="dots-loader"></div>
</div>
<!-- Content Area -->
<main class="flex-container" *ngIf="translationLoaded" [ngClass]="{ dark: isDarkMode }">
    <!-- Topbar -->
    <app-topbar class="d-print-none" *ngIf="isLoggedIn$ | async"></app-topbar>
    <div class="content-container">
        <!-- Navigation -->
        <app-navigation *ngIf="(isLoggedIn$ | async) && navigationVisible" #navigation></app-navigation>
        <!-- Content -->
        <content>
            <!-- Critical Announcements -->
            <app-critical-announcements *ngIf="isLoggedIn$ | async" [ngClass]="{ 'd-none': isLoadingRoute | async }"></app-critical-announcements>

            <!-- Notifying  Message -->
            <app-notifying-message *ngIf="errorInGetUserRequest$ | async as error" [message]="error"></app-notifying-message>

            <!-- Content Loader -->
            <div class="content-loading" *ngIf="isLoadingRoute | async">
                <div class="dots-loader">
                    <span class="sr-only">{{ "LOADING" | translate }}...</span>
                </div>
            </div>
            <!-- Router Outlet -->
            <div id="outlet-container" class="outlet-container" [ngClass]="{ 'd-none': isLoadingRoute | async }">
                <router-outlet #routerOutlet="outlet"></router-outlet>
            </div>
        </content>
    </div>
</main>
