<div
    class="navigation-container d-print-none"
    [ngClass]="{ isContracted: isContracted, isExpanded: isExpanded, isMobile: isMobile }"
    *ngIf="(isOpen && isMobile && (isLoggedIn | async)) || (!isMobile && (isContracted || isExpanded) && (isLoggedIn | async))"
>
    <div class="navigation">
        <nav role="navigation">
            <ul class="nav flex-column">
                <!-- Global Search -->
                <li class="global-search" *ngIf="!isContracted">
                    <div class="navigation-search">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-sm form-control-xs"
                                placeholder="{{ 'SEARCH' | translate }}..."
                                aria-label="Search"
                                [(ngModel)]="globalSearchVal"
                                (keyup)="$event.keyCode === 13 && globalSearch()"
                            />
                            <button class="btn btn-sm btn-primary" [disabled]="!globalSearchVal" type="button" id="search-button" (click)="globalSearch()">
                                <fa-icon icon="search" size="sm"></fa-icon>
                            </button>
                        </div>
                    </div>
                </li>
                <li class="nav-item" *ngFor="let link of links">
                    <!-- No Sublinks -->
                    <div *ngIf="!link.subLinks">
                        <a
                            data-zm="nav-link"
                            class="nav-link {{ link.bgcolorclass }}"
                            (click)="toggle(!isMobile ? true : false)"
                            routerLinkActive="active"
                            routerLink="/{{ link.router }}"
                            *ngIf="link.permission"
                            ><fa-icon icon="{{ link.icon }}" [fixedWidth]="true"></fa-icon><span class="link-name" translate>{{ link.name }}</span>
                            <span
                                class="badge badge-warning"
                                *ngIf="status && !link.warnings && !link.errors && link.activeCount && status[link.activeCount] > 0"
                                (click)="showActiveObjectList(link); $event.preventDefault()"
                            >
                                {{ status[link.activeCount] }}
                            </span>
                            <span
                                class="badge badge-warning"
                                *ngIf="link.warnings > 0 && link.errors === 0"
                                (click)="showWarningsObjectList(link); $event.preventDefault()"
                            >
                                {{ link.warnings }}
                            </span>
                            <span class="badge badge-danger" *ngIf="link.errors > 0" (click)="showErrorsObjectList(link); $event.preventDefault()">
                                {{ link.errors }}
                            </span>
                        </a>
                    </div>
                    <!-- Sublinks & Mobile -->
                    <div *ngIf="link.subLinks && isMobile && link.permission">
                        <a
                            routerLink="/{{ link.router }}"
                            routerLinkActive="active"
                            class="nav-link nav-dropdown {{ link.bgcolorclass }}"
                            (click)="link.isCollapsed = !link.isCollapsed"
                            [attr.aria-expanded]="!link.isCollapsed"
                            [attr.aria-controls]="link.name"
                            ><fa-icon icon="{{ link.icon }}" [fixedWidth]="true"></fa-icon><span class="link-name" translate>{{ link.name }}</span
                            ><fa-icon *ngIf="!link.isCollapsed" class="float-end" icon="angle-down" size="lg"></fa-icon
                            ><fa-icon *ngIf="link.isCollapsed" class="float-end" icon="angle-up" size="lg"></fa-icon
                        ></a>
                        <div id="{{ link.name }}" [ngbCollapse]="!link.isCollapsed">
                            <ul class="nav flex-column">
                                <li class="nav-item" *ngFor="let sublink of link.subLinks">
                                    <a
                                        *ngIf="sublink.permission"
                                        class="nav-link {{ link.bgcolorclass }}"
                                        routerLinkActive="active"
                                        routerLink="/{{ sublink.router }}"
                                        (click)="toggle()"
                                        translate
                                        >{{ sublink.name }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- Sublinks & Not Mobile -->
                    <div *ngIf="link.subLinks && !isMobile && link.permission">
                        <a routerLinkActive="active" routerLink="/{{ link.router }}" class="nav-link {{ link.bgcolorclass }}" aria-haspopup="true"
                            ><fa-icon icon="{{ link.icon }}" [fixedWidth]="true"></fa-icon><span class="link-name" translate>{{ link.name }}</span>
                            <fa-icon *ngIf="isExpanded" class="float-end" icon="caret-right"></fa-icon
                        ></a>
                        <div id="{{ link.name }}">
                            <ul class="nav dropdown" aria-label="submenu">
                                <li class="nav-item" *ngFor="let sublink of link.subLinks">
                                    <a
                                        *ngIf="sublink.permission"
                                        class="nav-link {{ link.bgcolorclass }}"
                                        routerLinkActive="active"
                                        routerLink="/{{ sublink.router }}"
                                        translate
                                        >{{ sublink.name }}</a
                                    >
                                </li>
                            </ul>
                        </div>
                    </div>
                </li>
            </ul>
        </nav>
    </div>
</div>
