import { ComponentRef } from "@angular/core";
import { KeyMap } from "src/app/models/shared";
import { LiveEvent } from "src/app/modules/live-events/pages/events/liveevent";
import { ZxLiveEventNameComponent } from "./zx-live-event-name.component";

export const assignComponentsLiveEventAdapter =
    (eventId: boolean) =>
    (
        statusComponentRef: ComponentRef<ZxLiveEventNameComponent>,
        row: KeyMap<LiveEvent>,
        searchTerm: string | string[]
    ) => {
        const statusCompRef = statusComponentRef.instance;
        statusCompRef.model = row;
        statusCompRef.showOtherIcons = eventId;
        statusCompRef.showStatusIcon = false;
        statusCompRef.showStatusText = false;
        statusCompRef.showLink = false;
        statusCompRef.showTag = false;
        statusCompRef.searchTerm = searchTerm?.toString();
    };
