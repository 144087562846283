import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import _ from "lodash";
import { StatusClassPipe } from "src/app/pipes/status-class.pipe";
import { Constants } from "src/app/constants/constants";
import { Source } from "src/app/models/shared";
import { urlBuilder } from "@zixi/shared-utils";
import { SourcesService } from "src/app/pages/sources/sources.service";
import { DeviceDetectorService } from "ngx-device-detector";

@Component({
    selector: "app-source-details-wrapper",
    templateUrl: "./source-details-wrapper.component.html"
})
export class SourceDetailsWrapperComponent implements OnChanges {
    @Input() source: Source | null;
    @Input() titlePlaceholder: string;
    @Input() icon = "external-link";
    @Input() iconTitle?: string;
    @Input() name?: string;
    @Input() secondRow?: boolean;
    @Input() locked?: boolean;
    @Input() overlay?: boolean;
    @Input() priority?: number;
    @Input() allowLivePlay?: boolean;
    @Output() iconClicked = new EventEmitter();

    detailsUrl: string;
    networkStatus: string;
    tr101Status: string;
    cqaStatus: string;
    canVLC = false;
    urls = Constants.urls;

    constructor(
        private statusClassPipe: StatusClassPipe,
        private sourcesService: SourcesService,
        private deviceService: DeviceDetectorService
    ) {
        this.canVLC = ["windows", "mac"].includes(this.deviceService.os.toLocaleLowerCase());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.source) return;
        const { firstChange, previousValue, currentValue } = changes.source;
        const isChanged = firstChange || !_.isEqual(previousValue, currentValue);
        if (isChanged) {
            this.setDetailsUrl();
            this.setNetworkStatus();
            this.setTr101Status();
            this.setCqaStatus();
        }
    }

    setDetailsUrl() {
        if (this.icon === "external-link")
            this.detailsUrl =
                this.source?.inputCluster?.dns_prefix && this.source?.name
                    ? `/${Constants.urls.sources}/${urlBuilder.encode(this.source.id)}/${this.source.name}`
                    : `/${Constants.urls.sources}`;
    }

    setNetworkStatus() {
        if (!this.source) return false;
        const hasNetwork =
            this.source.protocol !== "ndi" &&
            this.source.type !== "file" &&
            this.source.type !== "rtmp" &&
            this.source.protocol !== "hls_pull";
        this.networkStatus = this.statusClassPipe.transform(hasNetwork);
    }

    setTr101Status() {
        if (this.source?.status?.tr101?.status?.p1_ok == null) {
            this.tr101Status = null;
            return;
        }

        const isP1Ok = Boolean(this.source?.status?.tr101?.status.p1_ok);
        this.tr101Status = this.statusClassPipe.transform(isP1Ok);
    }

    setCqaStatus() {
        this.cqaStatus = null;
        // TODO IMPLEMENT - what indicates the CQA status? I didn't find any ref in HTML with the source context
    }
}
