import { DatePipe } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ActivationEnd, Router, RouterOutlet } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import _ from "lodash";
import { BehaviorSubject, firstValueFrom, Subscription } from "rxjs";
import { auditTime, filter, take } from "rxjs/operators";
import { ModalService } from "src/app/components/shared/modals/modal.service";
import { Constants } from "src/app/constants/constants";
import { KeyMap, Tag, UserPermissions } from "src/app/models/shared";
import { UsersService } from "src/app/pages/account-management/users/users.service";
import { StatusTextPipe } from "src/app/pipes/status-text.pipe";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { SharedService } from "src/app/services/shared.service";
import { LiveEventsService } from "../../../live-events.service";
import { LiveEvent } from "../liveevent";
import { DesnakePipe } from "src/app/pipes/desnake.pipe";
import { STAGE } from "@zixi/models";
import { LiveEventDetailsService } from "../../event-details/live-event-details.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxStatusFullComponent } from "src/app/components/shared/zx-status-full/zx-status-full.component";
import { assignComponentsStatusInputsFactory } from "src/app/components/shared/zx-status-full/zx-status-full.table-adapter";
import { assignDateTimeDisplayInputsFactory } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.table-adapter";
import { ZxLiveEventNameComponent } from "src/app/components/shared/zx-live-event-name/zx-live-event-name.component";
import { assignComponentsLiveEventAdapter } from "src/app/components/shared/zx-live-event-name/zx-live-event-name.table-adapter";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";
import { ZxDateTimeDisplayComponent } from "src/app/components/shared/zx-date-time-display/zx-date-time-display.component";
import { TitleService } from "src/app/services/title.service";
import { PushUpdatesService, CHANNELS, REFRESH_RATE_LIMIT } from "src/app/services/push-updates.service";
import { TypeFilter } from "src/app/components/events-filter-form/events-filter-form.component";
import { TourService, IStepOption } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";

interface State {
    searchTerm: string;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    typeFilters: { [key: string]: boolean };
    resourceTags: number[];
}

interface EventFrontData {
    countdown: string;
    nextStageName: string;
    stageName: string;
}

@Component({
    selector: "app-live-events-list",
    templateUrl: "./live-events-list.component.html",
    providers: [DatePipe]
})
export class LiveEventsListComponent implements OnInit, OnDestroy {
    @ViewChild(RouterOutlet) event: RouterOutlet;

    private eventsSubscription: Subscription;
    private refreshSubscription: Subscription;
    private routeSubscription: Subscription;

    liveEventId: number = null;
    urls = Constants.urls;
    resourceTags: Tag[];
    userPermissions: UserPermissions;
    refreshing = false;
    liveEvents: LiveEvent[] = [];
    selectedRows: Array<LiveEvent> = [];
    isResizing: boolean;
    private currentSortDirection: string;
    dateFormat = "medium";

    private tourSteps = TourSteps.eventsList;

    tableColumnsSchema: TableSchema<KeyMap<LiveEvent & EventFrontData>>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            sticky: 1,
            component: ZxLiveEventNameComponent,
            assignComponentsInputs: assignComponentsLiveEventAdapter(this.liveEventId ? true : undefined),
            textValue: row => row.name,
            sortBy: row => row.name,
            valueToExport: row => row.name
        },
        {
            header: this.translate.instant("STATUS"),
            columnDef: "status",
            width: 160,
            visible: true,
            component: ZxStatusFullComponent,
            assignComponentsInputs: assignComponentsStatusInputsFactory(),
            sortBy: row =>
                this.currentSortDirection === "asc"
                    ? row._sortData.sortableStatusAsc
                    : row._sortData.sortableStatusDesc,
            rowIf: liveEvent => liveEvent.stage !== "off" && liveEvent.stage !== "pending",
            valueToExport: row =>
                this.stp.transform(row).length > 0 ? this.translate.instant(this.stp.transform(row)) : ""
        },
        {
            header: this.translate.instant("START_TIME"),
            columnDef: "start_time",
            width: 160,
            visible: true,
            component: ZxDateTimeDisplayComponent,
            assignComponentsInputs: assignDateTimeDisplayInputsFactory<LiveEvent & EventFrontData>(
                row => row.start_time,
                this.dateFormat
            ),
            sortBy: row => new Date(row.start_time).getTime(),
            valueToExport: row => row.start_time
        },
        {
            header: this.translate.instant("STAGE"),
            columnDef: "stage",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row => row.stageName,
                row => row.stageName,
                () => true
            ),
            sortBy: row => row.stageName,
            valueToExport: row => this.desankePipe.transform(row.stage)
        },
        {
            header: this.translate.instant("COUNTDOWN"),
            columnDef: "countdown",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row => (row.staging_mode === "manual" ? this.translate.instant("MANUAL") : row.countdown),
                row => (row.staging_mode === "manual" ? this.translate.instant("MANUAL") : row.countdown),
                row => row.nextStageName !== "-"
            )
        },
        {
            header: this.translate.instant("NEXT_STAGE"),
            columnDef: "next_stage",
            width: 160,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row => row.nextStageName,
                row => row.nextStageName,
                row => row.nextStageName !== "off"
            ),
            sortBy: row => row.nextStageName
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            width: 90,
            visible: true,
            align: "right",
            stickyToLast: true,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<LiveEvent, Promise<void>>({
                canDeleteCallBack: liveEvent =>
                    this.sharedService.canEditZixiObject(liveEvent, this.resourceTags, this.userPermissions),
                canEditCallBack: liveEvent =>
                    liveEvent.stage === "pending" &&
                    this.sharedService.canEditZixiObject(liveEvent, this.resourceTags, this.userPermissions),
                canCloneCallBack: () => true,
                editRef: liveEvent => this.router.navigate([Constants.urls.liveevents, liveEvent.id, "edit"]),
                cloneRef: liveEvent => this.router.navigate([Constants.urls.liveevents, liveEvent.id, "clone"]),
                deleteRef: async liveEvent =>
                    await this.modalService.confirm(
                        "DELETE",
                        "LIVE_EVENT",
                        async () => {
                            const result = await this.es.deleteLiveEvent(liveEvent);
                            if (result) {
                                this.mixpanelService.sendEvent("delete live event", { id: liveEvent.id });
                            } else return false;
                        },
                        liveEvent.name
                    )
            })
        },
        {
            header: this.translate.instant("TAGS"),
            columnDef: "access_tags",
            width: 60,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row => row.resourceTags?.map(tag => tag.name).toString(),
                row => row.resourceTags?.map(tag => tag.name).toString(),
                () => true
            ),
            valueToExport: row => row.resourceTags?.map(tag => tag.name).join(", ")
        },
        {
            header: this.translate.instant("STAGE_EXPORT_COLUMN_HEADER"),
            columnDef: this.translate.instant("STAGE_EXPORT_COLUMN_HEADER"),
            width: 100,
            visible: false,
            hideFromColumnChooser: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<LiveEvent & EventFrontData>(
                row =>
                    row.stages
                        .map(stage => `(${stage.name}, ${stage.duration} minutes, ${!!stage.force_channel_slate})`)
                        .join(", "),
                row =>
                    row.stages
                        .map(stage => `(${stage.name}, ${stage.duration} minutes, ${!!stage.force_channel_slate})`)
                        .join(", "),
                () => true
            ),
            valueToExport: row =>
                row.stages
                    .map(stage => `(${stage.name}, ${stage.duration} minutes, ${!!stage.force_channel_slate})`)
                    .join(", ")
        }
    ];

    stagesFilter: TypeFilter[] = [
        { text: "Scheduled", color: "secondary", key: "pending", enabled: true },
        { text: "ACTIVE", color: "secondary", key: "active", enabled: true },
        { text: "Completed", color: "secondary", key: "off", enabled: true }
    ];

    columns: {
        status: boolean;
        start_time: boolean;
        stage: boolean;
        countdown: boolean;
        next_stage: boolean;
    };

    private filter = {
        events: true
    };

    private _filteredEvents: LiveEvent[];
    private eventsBS$ = new BehaviorSubject<(LiveEvent & EventFrontData)[]>([]);
    private filteredEventsBS$ = new BehaviorSubject<LiveEvent[]>([]);
    private state: State = {
        searchTerm: "",
        fromDate: null,
        toDate: null,
        typeFilters: {},
        resourceTags: []
    };

    constructor(
        public sharedService: SharedService,
        private userService: UsersService,
        private modalService: ModalService,
        public mixpanelService: MixpanelService,
        private translate: TranslateService,
        private es: LiveEventsService,
        private detailsService: LiveEventDetailsService,
        private stp: StatusTextPipe,
        private desankePipe: DesnakePipe,
        private router: Router,
        private titleService: TitleService,
        private pushUpdatesService: PushUpdatesService,
        public tourService: TourService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("LIVE_EVENTS"));
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children?.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.id) {
                    this.liveEventId = parseInt(event.snapshot.params.id, 10);
                } else {
                    this.liveEventId = null;
                }
            });
    }

    ngOnInit(): void {
        // resourceTags
        this.sharedService
            .getResourceTagsByType("live_events")
            .pipe(take(1))
            .subscribe((tags: Tag[]) => {
                this.resourceTags = tags;
            });

        this.userService.userPermissions.pipe(take(1)).subscribe(perm => {
            this.userPermissions = perm;
        });

        // local storage
        document.getElementById("left-container").style.flexBasis = localStorage.getItem("list-panel-width");
        this.searchTerm = localStorage.getItem("event.searchTerm") ? localStorage.getItem("event.searchTerm") : "";

        this.eventsSubscription = this.es.liveEvents$.subscribe(liveEvents => {
            this.liveEvents = this.liveEvents?.filter(liveEvent => liveEvents.find(i => i.id === liveEvent.id));
            // update existing events from the events list
            for (const liveEvent of liveEvents) {
                const existingEvent = this.liveEvents.find(eventItem => eventItem.id === liveEvent.id);
                if (!existingEvent) this.liveEvents.push(liveEvent);
                else Object.assign(existingEvent, liveEvent);
            }

            if (this.liveEvents) {
                this.prepTableData();
            }
        });

        this.refreshSubscription = this.pushUpdatesService
            .subscribeChannel({ name: CHANNELS.live_events, objectId: null })
            .pipe(auditTime(REFRESH_RATE_LIMIT)) // delay of 5 seconds, In case of consecutive publications, invoke refresh once every five seconds
            .subscribe(() => this.refresh());

        this.onFilterReset();

        this.tourService.initialize(this.tourSteps);
    }

    ngOnDestroy(): void {
        this.eventsSubscription.unsubscribe();
        this.refreshSubscription.unsubscribe();
        this.routeSubscription.unsubscribe();
    }

    async refresh() {
        this.refreshing = true;
        await firstValueFrom(this.es.refreshLiveEvents(true));
        this.refreshing = false;
    }

    selectAllRows() {
        this.selectedRows = this.filteredEvents;
    }

    onSort(s: string) {
        this.currentSortDirection = s;
    }

    selectRow = (liveEvent: LiveEvent) => {
        this.router.navigate([Constants.urls.liveevents, liveEvent.id]);
    };

    resizing(v: boolean) {
        this.isResizing = v;
    }

    canEditEvent(liveEvent: LiveEvent) {
        return (
            liveEvent.stage === "pending" &&
            this.sharedService.canEditZixiObject(liveEvent, this.resourceTags, this.userPermissions)
        );
    }

    canDeleteEvent(liveEvent: LiveEvent) {
        return (
            (liveEvent.stage === "pending" || liveEvent.stage === "off") &&
            this.sharedService.canEditZixiObject(liveEvent, this.resourceTags, this.userPermissions)
        );
    }

    async multiAction(action: string, func: (liveEvent: LiveEvent) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "LIVE_EVENT", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(this.translate.instant(action).toLowerCase() + " multiple events");
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (liveEvent: LiveEvent) => {
            return this.es.deleteLiveEvent(liveEvent);
        });
    }

    async multiEdit() {
        await this.modalService.editMultiple("LIVE_EVENT", this.selectedRows, async (liveEvent: LiveEvent, model) => {
            return this.es.updateLiveEvent(liveEvent.id, model);
        });
    }

    edit(liveEvent: LiveEvent): void {
        this.router.navigate([Constants.urls.liveevents, liveEvent.id, "edit"]);
    }

    clone(liveEvent: LiveEvent): void {
        this.router.navigate([Constants.urls.liveevents, liveEvent.id, "clone"]);
    }

    async delete(liveEvent: LiveEvent) {
        await this.modalService.confirm(
            "DELETE",
            "LIVE_EVENT",
            async () => {
                const id = liveEvent.id;
                const result = await this.es.deleteLiveEvent(liveEvent);
                if (result) {
                    this.mixpanelService.sendEvent("delete live event", { id });
                } else return false;
            },
            liveEvent.name
        );
    }
    get searchTerm() {
        return this.state.searchTerm;
    }
    set searchTerm(searchTerm: string) {
        this.setSearchTerm(searchTerm);
    }
    private _set(patch: Partial<State>) {
        Object.assign(this.state, patch);
        this.prepTableData();
    }

    get filteredEvents() {
        return this._filteredEvents;
    }

    set filteredEvents(filteredEvents: LiveEvent[]) {
        this._filteredEvents = _.cloneDeep(filteredEvents);
        this.filteredEventsBS$.next(this._filteredEvents);
    }

    get filterEventObservable() {
        return this.eventsBS$.asObservable();
    }

    public prepTableData() {
        if (this.liveEvents) {
            let events: LiveEvent[] = null;

            events = [...this.liveEvents];

            // 2. filter
            events = events.filter(liveEvent => {
                if (this.filter.events) return liveEvent;
            });

            events = events.filter(this.eventFilter);
            this.filteredEvents = events;

            this.eventsBS$.next(
                events.map(liveEvent => {
                    const next_stage = this.detailsService.nextStageName(liveEvent.stages, liveEvent.stage);
                    const countdown = this.countDownToNextStage(liveEvent, next_stage);

                    const nextStageName = this.getStageName(next_stage);
                    const stageName = this.getStageName(liveEvent.stage);

                    return Object.assign(liveEvent, {
                        countdown: liveEvent.stage === "off" ? "-" : countdown,
                        nextStageName: liveEvent.stage === "off" ? "-" : nextStageName,
                        stageName
                    });
                })
            );
        }
    }

    eventFilter = (liveEvent: LiveEvent) => {
        if (this.state.searchTerm) {
            const searchMatch = this.sharedService.matches(liveEvent, this.state.searchTerm, () =>
                liveEvent.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
            );
            if (!searchMatch) return false;
        }

        if (this.state.fromDate || this.state.toDate) {
            const startTimeMoment = moment(liveEvent.start_time);

            let dateMatch = true;
            if (!this.state.toDate) {
                dateMatch = startTimeMoment.isAfter(this.state.fromDate);
            } else if (!this.state.fromDate) {
                dateMatch = startTimeMoment.isBefore(this.state.toDate);
            } else {
                dateMatch = startTimeMoment.isBetween(this.state.fromDate, this.state.toDate);
            }

            if (!dateMatch) return false;
        }

        if (this.state.typeFilters) {
            if (
                this.state.typeFilters.active === false &&
                !(liveEvent.stage === "pending" || liveEvent.stage === "off")
            ) {
                return false;
            }
            if (this.state.typeFilters[liveEvent.stage] === false) {
                return false;
            }
        }

        if (this.state.resourceTags?.length > 0) {
            if (!liveEvent.resourceTags.find(tag => this.state.resourceTags.includes(tag.id))) return false;
        }

        return true;
    };

    countDownToNextStage(liveEvent: LiveEvent, nextStageName: STAGE): string {
        const stage = liveEvent.actions?.find(s => s.liveEventStage?.name === liveEvent.stage);
        const nextStage = liveEvent.actions?.find(
            s => s.liveEventStage?.name.toLowerCase() === nextStageName.toLowerCase()
        );

        const diff = moment(nextStage?.scheduled_time).diff(moment(stage?.scheduled_time ?? moment()), "seconds");

        return moment.duration(diff, "seconds").humanize();
    }

    // filter form
    onFilterApply(filter) {
        this.setFilters({
            ...filter,
            searchTerm: filter.msgFilter || "",
            typeFilters: filter.msgTypes
        });

        this.prepTableData();
    }

    onFilterReset() {
        this.setFilters({
            searchTerm: "",
            fromDate: null,
            toDate: null,
            typeFilters: {},
            resourceTags: []
        });

        this.prepTableData();
    }

    setFilters(filter) {
        this.state = { ...this.state, ...filter };
        this.setSearchTerm(this.state.searchTerm);
    }

    setSearchTerm(searchTerm: string) {
        this._set({ searchTerm });
        localStorage.setItem("event.searchTerm", searchTerm);
    }

    getStageName(stageCode: STAGE) {
        const columnValue = this.stagesFilter.find(f => f.key === stageCode)?.text ?? "";
        if (columnValue) return columnValue;
        return stageCode.charAt(0).toUpperCase() + stageCode.slice(1);
    }
}
