import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { LoginService } from "./login.service";
import { SSO } from "./sso";

@Injectable({
    providedIn: "root"
})
export class SSOsResolverService implements Resolve<SSO[]> {
    constructor(private loginService: LoginService) {}

    resolve(): Observable<SSO[]> | Observable<never> {
        return new Observable<SSO[]>((observe: Subscriber<SSO[]>) => {
            this.loginService.listSSOs().subscribe((ssos: SSO[]) => {
                observe.next(ssos);
                observe.complete();
            });
        });
    }
}
