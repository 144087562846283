import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import {
    ActivationStart,
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    Router,
    RouterEvent
} from "@angular/router";
import { BehaviorSubject, Observable, Subscription } from "rxjs";
import { AuthService } from "./services/auth.service";
import { MixpanelService } from "./services/mixpanel.service";
import { Constants } from "./constants/constants";
import { NavigationService } from "./components/navigation/navigation.service";
import { UsersService } from "./pages/account-management/users/users.service";
import { TourService } from "ngx-ui-tour-md-menu";

export class NavEvent {
    fromURL?: string;
    toURL?: string;
    redirectURL?: string;
    nextURL?: string;
}

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {
    title = "zen";
    translationLoaded = false;

    constants = Constants;
    isLoggedIn$: Observable<boolean>;
    isLoggedIn: boolean;

    private lastNav: NavEvent;
    private isLoading = new BehaviorSubject<boolean>(true);

    private isNavVisibleSubscription: Subscription;
    navigationVisible: boolean;

    private isDarkModeSubscription: Subscription;
    isDarkMode: boolean;

    get isLoadingRoute() {
        return this.isLoading.asObservable();
    }
    setLoading(val: boolean) {
        this.isLoading.next(val);
    }

    get lastNavEvent() {
        return this.lastNav;
    }
    setLastNav(event: NavEvent) {
        this.lastNav = event;
    }

    errorInGetUserRequest$ = this.usersService.getErrorInRequest;

    constructor(
        private translate: TranslateService,
        private router: Router,
        private authService: AuthService,
        private mixpanelService: MixpanelService,
        private navigationService: NavigationService,
        private usersService: UsersService,
        private tourService: TourService
    ) {
        // Translation
        this.translate.addLangs(["en"]);
        this.translate.setDefaultLang("en");
        this.translate.use("en").subscribe(() => {
            this.translationLoaded = true;
        });

        //
        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            this.isLoggedIn = isLoggedIn;
        });

        // Show spinner while waiting for navigation to end
        this.router.events.subscribe((event: Event | RouterEvent) => {
            switch (true) {
                // Changed to ActivationStart instead of NavigatonStart so confirm-navigation dialog works
                case event instanceof ActivationStart: {
                    // Start Loading
                    this.setLoading(true);
                    break;
                }

                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    // TODO: added event instanceof RouterEvent
                    if (event instanceof RouterEvent && event.url === "/login" && this.isLoggedIn) {
                        // Add redirect to dashboard if user is already logged in
                        this.router.navigate([this.constants.urls.dashboard]);
                    }
                    // Mixpanel
                    if (this.mixpanelService.isMixpanelStarted) {
                        // From Page, Next Page and End Event Timer
                        const lastNavEvent = this.lastNavEvent;
                        // TODO: added event instanceof RouterEvent
                        if (lastNavEvent && lastNavEvent.toURL && event instanceof RouterEvent) {
                            lastNavEvent.nextURL = this.handleUrl(event.url);
                            this.mixpanelService.sendEvent(lastNavEvent.toURL, lastNavEvent);
                        }

                        // To Page and Start Event Timer
                        if (event instanceof NavigationEnd && event.urlAfterRedirects) {
                            const navEvent: NavEvent = {
                                fromURL: lastNavEvent && lastNavEvent.toURL ? lastNavEvent.toURL : null,
                                toURL: this.handleUrl(event.url)
                            };
                            this.setLastNav(navEvent);
                            this.mixpanelService.timeEvent(navEvent.toURL);
                        }
                    }
                    // End Loading
                    this.setLoading(false);
                    break;
                }
                default: {
                    break;
                }
            }
        });
    }

    handleUrl(url: string) {
        const sections = url.split("/");

        let eventName: string;
        if (sections[1] && !sections[2]) {
            eventName = sections[1];
        } else if (sections[1] && sections[2] && !sections[3]) {
            if (
                sections[1] === "grids" ||
                sections[1] === "transformation" ||
                sections[1] === "account-management" ||
                sections[1] === "configuration" ||
                sections[1] === "logs"
            )
                eventName = sections[1] + "/" + sections[2];
            else if (sections[2] === "new") eventName = sections[1] + "/new";
            else eventName = sections[1] + "/detail";
        } else if (sections[1] && sections[2] && sections[3] && !sections[4]) {
            if (sections[3] === "edit" || sections[3] === "clone") eventName = sections[1] + "/detail/" + sections[3];
            else if (
                sections[1] === "grids" ||
                (sections[1] === "targets" && sections[3] !== "new") ||
                sections[1] === "transformation" ||
                sections[1] === "account-management" ||
                sections[1] === "logs"
            )
                eventName = sections[1] + "/" + sections[2] + "/detail";
            else if (sections[1] === "sources" && sections[3] !== "new") eventName = sections[1] + "/cluster/detail";
            else eventName = sections[1] + "/" + sections[2] + "/" + sections[3];
        } else if (sections[1] && sections[2] && sections[3] && sections[4] && !sections[5]) {
            if (
                sections[1] === "grids" ||
                sections[1] === "targets" ||
                sections[1] === "transformation" ||
                sections[1] === "account-management" ||
                sections[1] === "logs"
            )
                eventName = sections[1] + "/" + sections[2] + "/detail/" + sections[4];
            else if (sections[1] === "channels") eventName = sections[1] + "/" + sections[2] + "/cluster/detail";
            else eventName = sections[1] + "/" + sections[2] + "/" + sections[3] + "/" + sections[4];
        } else if (sections[1] && sections[2] && sections[3] && sections[4] && sections[5] && !sections[6]) {
            if (sections[1] === "channels")
                eventName = sections[1] + "/" + sections[2] + "/cluster/detail/" + sections[5];
            else eventName = sections[1] + "/" + sections[2] + "/cluster/detail/" + sections[5];
        } else {
            eventName = "/";
        }

        return eventName;
    }

    ngOnInit() {
        const darkMode = localStorage.getItem("dark-mode");

        if (darkMode === "true") this.toggleDarkMode();
        if (darkMode === null) this.toggleDarkMode();

        this.isLoggedIn$ = this.authService.isLoggedIn;

        this.isNavVisibleSubscription = this.navigationService.isNavVisible.pipe().subscribe(bool => {
            this.navigationVisible = bool;
        });

        this.isDarkModeSubscription = this.navigationService.isDarkMode.pipe().subscribe(bool => {
            this.isDarkMode = bool;
        });
    }

    ngOnDestroy() {
        this.isNavVisibleSubscription.unsubscribe();
        this.isDarkModeSubscription.unsubscribe();
    }

    toggleDarkMode() {
        this.navigationService.setDarkMode(!this.isDarkMode);
    }
}
