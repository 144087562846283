<form id="form" #form="ngForm">
    <div class="form-group">
        <label for="clips">{{ "CLIPS" | translate }}</label>
        <div>
            <table class="table table-sm bordered m-0">
                <thead>
                    <tr>
                        <th scope="col" class="maxw-40px">{{ "NAME" | translate }}</th>
                        <th scope="col" class="maxw-40px">{{ "URL" | translate }}</th>
                        <th scope="col" class="w-40px text-end"></th>
                    </tr>
                </thead>
                <tbody *ngIf="clips.length > 0">
                    <tr *ngFor="let clip of clips; let index = index; trackBy: trackByIndex">
                        <td>
                            <input
                                type="text"
                                placeholder="{{ 'NAME' | translate }}"
                                id="name_{{ index }}"
                                name="name_{{ index }}"
                                [(ngModel)]="clips[index].name"
                                required
                                class="form-control form-control-sm form-control-xs"
                                [ngClass]="{ 'is-invalid': submitted && form.controls['name_' + index]?.errors }"
                                (change)="clipsChanged.emit(clips)"
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                placeholder="{{ 'CLIP' | translate }} {{ 'URL' | translate }}"
                                id="clip_{{ index }}"
                                name="clip_{{ index }}"
                                [(ngModel)]="clips[index].url"
                                required
                                class="form-control form-control-sm form-control-xs"
                                pattern="^https?:\/\/.*\/.*(\?.*)?$"
                                [ngClass]="{ 'is-invalid': submitted && form.controls['clip_' + index]?.errors }"
                                (change)="clipsChanged.emit(clips)"
                            />
                        </td>

                        <td class="text-end">
                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-outline-secondary" (click)="deselectClip(index)">
                                <fa-icon icon="minus" size="sm"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <td colspan="3" class="text-end">
                            <button type="button" class="btn btn-sm btn-xs btn-round-xs btn-primary" (click)="addClip()">
                                {{ "ADD" | translate }} <fa-icon icon="plus" size="sm"></fa-icon>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</form>
