import { Injectable, inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ReCaptchaV3Service } from "ng-recaptcha";
import { Constants } from "../../constants/constants";
import { APIResponse } from "src/app/models/shared";
import { firstValueFrom } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ForgotPasswordService {
    private recaptchaV3Service = inject(ReCaptchaV3Service);
    constructor(private http: HttpClient) {}

    async forgot(email: string) {
        const recaptchaKey = await firstValueFrom(this.recaptchaV3Service.execute("forgot_password"));
        const options = {
            headers: {
                dns_prefix: location.hostname === "localhost" ? "zixi" : location.hostname.split(".")[0]
            }
        };

        return firstValueFrom(
            this.http.post<APIResponse<unknown>>(
                Constants.apiUrl + Constants.apiUrls.forgot,
                {
                    email,
                    recaptchaKey
                },
                options
            )
        );
    }
}
