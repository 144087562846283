import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
    enableProdMode();
}

if (process.env.NODE_ENV === "production") {
    // NOTE: This ENV value is being provided by Webpack's DefinePlugin. It is populated
    // on the mode in which the webpack-cli was invoked.
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
