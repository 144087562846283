<content-loading-animation *ngIf="loadingStatus || loadingIssues"></content-loading-animation>
<div class="page-container" *ngIf="!loadingStatus && !loadingIssues">
    <div class="page-content">
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 data-zm="page-title" title="{{ 'DASHBOARD' | translate }}">
                    {{ "DASHBOARD" | translate
                    }}<fa-icon
                        class="ms-2 tourStartButton"
                        icon="question-circle"
                        #tooltip="matTooltip"
                        matTooltip="{{ 'YOU_CAN_LEARN_MORE_ABOUT_THE_DASHBOARD_BY_CLICKING_HERE' | translate }}"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        size="xs"
                        (click)="tourService.start(); mixpanelService.sendEvent('start dashboard feature guide')"
                    ></fa-icon>
                </h1>
            </div>
        </div>
        <div class="content-area" tourAnchor="dashboardAnchor">
            <div class="container-fluid">
                <div class="row">
                    <!-- Announcements -->
                    <ng-container *ngIf="nonCriticalAnnouncements$ | async as nonCriticalAnnouncements">
                        <div *ngIf="nonCriticalAnnouncements.length" class="col-12">
                            <ngb-accordion #announcementsAccordion="ngbAccordion" activeIds="ngb-panel-announcements" class="no-padding">
                                <ngb-panel id="ngb-panel-announcements">
                                    <ng-template ngbPanelTitle>
                                        <h3 translate title="{{ 'ANNOUNCEMENTS' | translate }}">ANNOUNCEMENTS</h3>
                                        <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                                        <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                                    </ng-template>
                                    <ng-template ngbPanelContent>
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-12 pt-3">
                                                    <app-announcement
                                                        *ngFor="let announcement of nonCriticalAnnouncements"
                                                        [announcement]="announcement"
                                                        (onDismiss)="dismissAnnouncement(announcement)"
                                                    ></app-announcement>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-template>
                                </ngb-panel>
                            </ngb-accordion>
                        </div>
                    </ng-container>

                    <!-- Status -->
                    <div class="col-12 col-xxl-4">
                        <div class="row">
                            <div class="col-12 col-lg-6 col-xxl-12">
                                <ngb-accordion #statusAccordion="ngbAccordion" activeIds="ngb-panel-status" class="no-padding">
                                    <ngb-panel id="ngb-panel-status">
                                        <ng-template ngbPanelTitle>
                                            <h3 translate title="{{ 'STATUS' | translate }}">STATUS</h3>
                                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div class="table-responsive">
                                                <table class="table table-sm mb-0">
                                                    <tbody>
                                                        <tr *ngFor="let s of status | keyvalue : sharedService.keepOrder">
                                                            <td class="w-160px">
                                                                <strong>{{ s.key | uppercase | translate }}</strong>
                                                            </td>
                                                            <td>
                                                                <span class="me-3" *ngIf="s.value.ok">
                                                                    <fa-icon
                                                                        icon="check-circle"
                                                                        class="status-good me-1"
                                                                        title="{{ 'ALL_GOOD' | translate }}"
                                                                    ></fa-icon
                                                                    >{{ s.value.ok }}
                                                                </span>
                                                                <span class="me-3" *ngIf="s.value.warning">
                                                                    <fa-icon
                                                                        icon="exclamation-circle"
                                                                        class="status-warning me-1"
                                                                        title="{{ 'WARNING' | translate }}"
                                                                    ></fa-icon
                                                                    >{{ s.value.warning }}
                                                                </span>
                                                                <span class="me-3" *ngIf="s.value.muted_warning">
                                                                    <fa-icon
                                                                        class="status-warning me-1"
                                                                        icon="bell-slash"
                                                                        title="{{ 'WARNING_STATE_MUTED' | translate }}"
                                                                    ></fa-icon
                                                                    >{{ s.value.muted_warning }}
                                                                </span>
                                                                <span class="me-3" *ngIf="s.value.error">
                                                                    <fa-icon
                                                                        icon="minus-circle"
                                                                        class="status-bad me-1"
                                                                        title="{{ 'ERROR' | translate }}"
                                                                    ></fa-icon
                                                                    >{{ s.value.error }}
                                                                </span>
                                                                <span class="me-3" *ngIf="s.value.acknowledged">
                                                                    <fa-icon
                                                                        class="status-bad me-1"
                                                                        icon="check"
                                                                        title="{{ 'ERROR_STATE_ACKNOWLEDGED' | translate }}"
                                                                    ></fa-icon
                                                                    >{{ s.value.acknowledged }}
                                                                </span>
                                                                <span class="me-3" *ngIf="s.value.muted_error">
                                                                    <fa-icon
                                                                        class="status-bad me-1"
                                                                        icon="bell-slash"
                                                                        title="{{ 'ERROR_STATE_MUTED' | translate }}"
                                                                    ></fa-icon
                                                                    >{{ s.value.muted_error }}
                                                                </span>
                                                                <span *ngIf="s.value.other">
                                                                    <fa-icon
                                                                        icon="circle"
                                                                        class="status-disabled me-1"
                                                                        title="{{ 'DISABLED/PENDING' | translate }}"
                                                                    ></fa-icon
                                                                    >{{ s.value.other }}
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="status.length === 0">
                                                            <td colspan="2" class="text-center">{{ "ALL_GOOD" | translate }}!</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                            <div class="col-12 col-lg-6 col-xxl-12">
                                <!-- Issues -->
                                <ngb-accordion #issuesAccordion="ngbAccordion" activeIds="ngb-panel-issues" class="no-padding">
                                    <ngb-panel id="ngb-panel-issues">
                                        <ng-template ngbPanelTitle>
                                            <h3 title="{{ 'ACTIVE_ERRORS' | translate }}">
                                                {{ "ACTIVE_ERRORS" | translate
                                                }}<span class="ms-1 badge badge-danger" *ngIf="issues.length > 0">
                                                    {{ issues.length }}
                                                </span>
                                            </h3>
                                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <app-table-list
                                                [tableName]="'active-events'"
                                                [displayTableName]="'ACTIVE_ERRORS' | translate"
                                                [data]="events$ | async"
                                                [(tableSchema)]="tableEventsColumnsSchema"
                                                [showReport]="false"
                                                [showFilter]="false"
                                                [showColumnsSelection]="false"
                                                [showSelectionCheckbox]="false"
                                                [autoRows]="false"
                                                [selectable]="false"
                                                [hoverable]="false"
                                            >
                                            </app-table-list>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xxl-8">
                        <ngb-accordion #incidentsAccordion="ngbAccordion" activeIds="ngb-panel-incidents" class="no-padding">
                            <ngb-panel id="ngb-panel-incidents">
                                <ng-template ngbPanelTitle>
                                    <h3 title="{{ 'ACTIVE_INCIDENTS' | translate }}">
                                        {{ "ACTIVE_INCIDENTS" | translate }}
                                        <small
                                            ><a href="/incidents"
                                                >(<u>{{ "VIEW_ALL" | translate }}</u
                                                >)</a
                                            ></small
                                        >
                                    </h3>
                                    <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                                    <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <!-- Table -->
                                    <app-table-list
                                        [tableName]="'active-incidents'"
                                        [displayTableName]="'INCIDENTS' | translate"
                                        [data]="incidents$ | async"
                                        [(tableSchema)]="tableColumnsSchema"
                                        (rowSelected)="selectRow($event)"
                                        [showReport]="false"
                                        [showFilter]="false"
                                        [showColumnsSelection]="false"
                                        [showSelectionCheckbox]="false"
                                        [autoRows]="false"
                                    >
                                    </app-table-list>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>

                        <ngb-accordion #eventsAccordion="ngbAccordion" activeIds="ngb-panel-events" class="no-padding">
                            <ngb-panel id="ngb-panel-events">
                                <ng-template ngbPanelTitle>
                                    <h3 translate title="{{ 'RECENT_EVENTS_LOG' | translate }}">
                                        {{ "RECENT_EVENTS_LOG" | translate }}
                                        <small
                                            ><a href="/logs/events"
                                                >(<u>{{ "VIEW_ALL" | translate }}</u
                                                >)</a
                                            ></small
                                        >
                                    </h3>
                                    <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                                    <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <zx-events [objects]="{}" [quickReport]="false" [autoRows]="false"></zx-events>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
