import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";

import { LoginService } from "./login.service";
import { AccountSettings } from "../../services/settings.service";

@Injectable({
    providedIn: "root"
})
export class CustomerSettingsResolverService implements Resolve<AccountSettings> {
    constructor(private loginService: LoginService) {}

    resolve(): Promise<AccountSettings> | Promise<never> {
        return this.loginService.allowNonSSOLoginSetting();
    }
}
