import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import _ from "lodash";

import { Constants } from "src/app/constants/constants";

import { TargetsDetails } from "../live-event-details.service";
import { Status } from "../circle-status-indicator/circle-status-indicator.component";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-targets-abbreviated-details",
    templateUrl: "./targets-abbreviated-details.component.html"
})
export class TargetsAbbreviatedDetailsComponent implements OnChanges {
    @Input() targetsDetails: TargetsDetails;

    targetsQuantity: number;
    deliveryChannelUrl: string;
    targetsStatuses: {
        [Status.GOOD]: number;
        [Status.WARNING]: number;
        [Status.BAD]: number;
        [Status.DISABLE]: number;
    };
    targetsUrls: string[];

    ngOnChanges(changes: SimpleChanges): void {
        const { firstChange, previousValue, currentValue } = changes.targetsDetails;
        const isChanged = firstChange || !_.isEqual(previousValue, currentValue);
        if (isChanged) {
            this.targetsQuantity = this.targetsDetails.targets?.length;
            if (!this.targetsQuantity) {
                return;
            }
            if (this.targetsQuantity > 3) {
                this.setTargetsSummary();
            } else {
                this.setTargetsDescription();
            }
        }
    }

    setTargetsDescription() {
        this.targetsUrls = this.targetsDetails.targets.map(target => {
            return `/${Constants.urls.targets}/${target.apiType}/${urlBuilder.encode(target.objId)}/${
                target.target.name
            }`;
        });
    }

    setTargetsSummary() {
        this.deliveryChannelUrl = `/${Constants.urls.channels}/${
            Constants.urls.channelTypes.delivery
        }/${urlBuilder.encode(this.targetsDetails.deliveryChannel.id)}/${this.targetsDetails.deliveryChannel.name}`;
        // TODO IMPLEMENT - add summary.warning after it will implement on the back /
        const { bad, muted_bad, disabled, pending, good } = this.targetsDetails.summary;
        this.targetsStatuses = {
            [Status.GOOD]: good,
            [Status.WARNING]: 0,
            [Status.BAD]: muted_bad + bad,
            [Status.DISABLE]: disabled + pending
        };
    }
}
