<div class="forgot-container">
    <div class="row">
        <div class="col text-center">
            <img width="250" alt="ZEN Master logo" class="align-middle" src="../../../assets/images/ZEN-Master-Logo-white.png" />
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card mt-3 mb-3 text-start mx-auto" [ngStyle]="{ 'width.px': 320 }">
                <div class="card-body">
                    <form #form="ngForm" (ngSubmit)="form.valid && onSubmit()">
                        <h3>{{ "FORGOT_PASSWORD" | translate }}?</h3>
                        <!-- Email -->
                        <div class="form-group">
                            <label for="email">{{ "EMAIL" | translate }}</label>
                            <input
                                type="email"
                                class="form-control"
                                id="email"
                                name="email"
                                [(ngModel)]="emailAddress"
                                placeholder="Email"
                                [ngClass]="{ 'is-invalid': form.submitted && email.errors }"
                                #email="ngModel"
                                required
                            />
                            <div *ngIf="email.invalid" class="invalid-feedback">
                                <div *ngIf="email.errors.required">{{ "EMAIL" | translate }} {{ "IS_REQUIRED" | translate }}.</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button [disabled]="loading" zmid="form-submit" type="submit" class="btn btn-primary">
                                {{ "SEND" | translate }} {{ "EMAIL" | translate }}
                                <span *ngIf="loading && !done" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                            </button>
                        </div>
                        <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
                        <div *ngIf="done" class="alert alert-success mb-3">{{ "FORGOT_PASSWORD_SUBMITTED" | translate }}</div>
                        <div class="form-group">
                            <a class="mt-2" routerLink="/login">{{ "SIGN_IN" | translate }}</a>
                        </div>
                    </form>
                </div>
            </div>
            <app-launch-page-footer></app-launch-page-footer>
        </div>
    </div>
</div>
