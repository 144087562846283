<content-loading-animation *ngIf="loading"></content-loading-animation>

<app-form-wrapper
    [formGroup]="form"
    *ngIf="!loading"
    [titleMsg]="'LIVE_EVENTS' | translate"
    [isEdit]="isEdit"
    [isClone]="isClone"
    [hasTourStartButton]="true"
    tourStartTooltipString="YOU_CAN_LEARN_MORE_ABOUT_CREATING_LIVE_EVENTS_BY_CLICKING_HERE"
    mixpanelString="start create live event guide"
>
    <section id="info">
        <app-form-section-title [title]="'INFO' | translate" tourAnchor="firstEventFormAnchor"></app-form-section-title>

        <app-input-generic-field
            [formControl]="nameControl"
            [textFieldName]="'NAME' | translate"
            [disabled]="saving"
            type="text"
            [isParentFormSubmitted]="isSubmitted"
        ></app-input-generic-field>

        <zx-access-tags-select
            [formControl]="tagsControl"
            [label]="'TAGS' | translate"
            name="resource_tag_ids"
            [isParentFormSubmitted]="isSubmitted"
        ></zx-access-tags-select>

        <app-zx-channel-select
            tourAnchor="secondEventFormAnchor"
            [formControl]="failoverChannelsControl"
            [label]="'CHANNELS' | translate"
            [channelType]="'failover'"
            [isParentFormSubmitted]="isSubmitted"
        ></app-zx-channel-select>

        <app-zx-string-selection
            [formControl]="selectModeControl"
            [selectionOptions]="options"
            [label]="'STAGING_MODE' | translate"
            [isParentFormSubmitted]="isSubmitted"
            tourAnchor="thirdEventFormAnchor"
        ></app-zx-string-selection>

        <div class="form-group">
            <div class="form-check">
                <input
                    type="checkbox"
                    class="form-check-input"
                    id="disableChannelCheckbox"
                    name="disableChannelCheckbox"
                    [checked]="disableChannelControl.value"
                    (change)="disableChange()"
                />
                <label class="form-check-label" for="disableChannelCheckbox">{{ "DISABLE_CHANNELS_AFTER_END" | translate }}</label>
            </div>
        </div>

        <app-zx-date-picker
            [formControl]="startTimeControl"
            [label]="'START_TIME' | translate"
            [isParentFormSubmitted]="isSubmitted"
            [onlyFutureDates]="true"
            tourAnchor="fourthEventFormAnchor"
        ></app-zx-date-picker>

        <zx-live-event-stage-table
            [submitted]="isSubmitted"
            [stages]="stages"
            [isStagingAuto]="selectModeControl.value === 'Auto'"
            [clips]="clips"
            (stagesChanged)="stagesChanged($event)"
        ></zx-live-event-stage-table>

        <zx-live-event-clip-table (clipsChanged)="clipChanged($event)" [submitted]="isSubmitted" [clips]="clips"></zx-live-event-clip-table>

        <app-error [marginBottom]="true" [marginTop]="false"></app-error>

        <app-zx-form-bottom-buttons
            (submit)="onSubmit()"
            [isFormValid]="form.valid"
            [(submitted)]="isSubmitted"
            [cancelUrlNavigate]="exitUrl"
            [isSaving]="saving"
            [isEdit]="false"
        ></app-zx-form-bottom-buttons>
    </section>
</app-form-wrapper>
