<div class="h-100 p-1 d-flex flex-column justify-content-between">
    <div>
        <div class="d-flex flex-row justify-content-between">
            <h5 class="ellipsis">{{ broadcasterName ?? ("BROADCASTER" | translate) }}</h5>
            <a [routerLink]="clusterPageWithBroadcasterSelectedUrl" target="_blank"><fa-icon icon="external-link" size="sm"></fa-icon></a>
        </div>
        <h6 *ngIf="broadcasterClusterName" class="mb-1 mt-2">{{ "CLUSTER" | translate }}</h6>
        <h5 *ngIf="broadcasterClusterName" class="mb-2">{{ broadcasterClusterName }}</h5>
    </div>
    <div class="d-flex flex-wrap flex-row justify-content-between">
        <app-circle-status-indicator *ngIf="cpuStatus" [title]="'CPU' | translate" [status]="cpuStatus"></app-circle-status-indicator>
        <app-circle-status-indicator *ngIf="memoryStatus" [title]="'RAM' | translate" [status]="memoryStatus"></app-circle-status-indicator>
        <app-circle-status-indicator *ngIf="hddStatus" [title]="'HDD' | translate" [status]="hddStatus"></app-circle-status-indicator>
    </div>
</div>
