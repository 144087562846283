<nav class="navbar navbar-dark bg-dark" *ngIf="isLoggedIn$ | async" [ngClass]="{ impersonating: impersonating$ | async }">
    <a class="navbar-brand" href="javascript:void(0)">
        <button type="button" class="btn btn-link btn-nav-toggle" (click)="toggleNav()" *ngIf="navigationVisible">
            <fa-icon class="expand-icon" icon="bars" size="lg"></fa-icon>
        </button>
        <img width="124" alt="ZEN Master logo" class="align-middle" (click)="goToHome()" src="assets/images/ZEN-Master-Logo-white.png" />
    </a>
    <zx-breadcrumbs class="d-none d-md-flex" [ngClass]="{ 'ms-0': (isNavExpanded$ | async) === false }"></zx-breadcrumbs>
    <span class="white ms-auto" *ngIf="impersonating$ | async"
        ><fa-icon icon="user-secret" size="md"></fa-icon><span class="d-none d-md-inline-block ms-1">{{ "IMPERSONATING" | translate }}</span
        ><span class="ms-1">-&nbsp;{{ dnsPrefix$ | async }}</span
        ><a class="ms-1" href="/api/impersonate/zixi"><fa-icon icon="times-circle" size="md"></fa-icon></a
    ></span>
    <button type="button" class="d-none d-xs-flex btn btn-link navbar-text ms-auto" (click)="myAccount()">
        <fa-icon icon="user"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ userName$ | async }}</span>
    </button>
    <div ngbDropdown display="dynamic" class="ms-auto ms-xs-0">
        <button class="btn btn-link p-0 pe-2 ps-1" id="sortByDropdown" ngbDropdownToggle title="{{ 'HELP' | translate }}">
            <fa-icon icon="question-circle" size="md"></fa-icon>
            <span class="d-none d-lg-inline-block ms-1">{{ "HELP" | translate }}</span>
        </button>
        <div ngbDropdownMenu aria-labelledby="sortByDropdown" class="shadow-sm">
            <h5 class="dropdown-item-text">{{ "HELP_MENU.DOCUMENTATION" | translate }}</h5>
            <button class="text-primary" ngbDropdownItem (click)="help()">
                {{ "USER_GUIDE" | translate }}
            </button>
            <button *ngFor="let helpConfig of helpConfigs" class="text-primary" ngbDropdownItem (click)="help(helpConfig.link)">
                {{ helpConfig.menu | translate }}
            </button>
            <h5 class="dropdown-item-text">{{ "HELP_MENU.HELP_US" | translate }}</h5>
            <button class="text-primary" ngbDropdownItem (click)="reportIssue()">
                {{ "REPORT_ISSUE" | translate }}
            </button>
        </div>
    </div>
    <button type="button" class="btn btn-outline-secondary darkmode-button" (click)="toggleDarkMode()">
        <fa-icon icon="moon" size="sm" *ngIf="!isDarkMode"></fa-icon>
        <fa-icon icon="sun" size="sm" *ngIf="isDarkMode"></fa-icon>
        <span class="sr-only" *ngIf="!isDarkMode">{{ "DARK_MODE" | translate }}</span>
        <span class="sr-only" *ngIf="isDarkMode">{{ "LIGHT_MODE" | translate }}</span>
    </button>
    <button data-zm="logout-button" type="button" class="btn btn-outline-navbar logout-button" (click)="logout()" title="{{ 'LOGOUT' | translate }}">
        <fa-icon icon="sign-out-alt"></fa-icon>
        <span class="d-none d-lg-inline-block ms-1">{{ "LOGOUT" | translate }}</span>
    </button>
</nav>
