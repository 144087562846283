import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import * as _ from "lodash";

import { Broadcaster } from "src/app/models/shared";
import { Status } from "../circle-status-indicator/circle-status-indicator.component";
import { urlBuilder } from "@zixi/shared-utils";

@Component({
    selector: "app-broadcaster-abbreviated-details",
    templateUrl: "./broadcaster-abbreviated-details.component.html"
})
export class BroadcasterAbbreviatedDetailsComponent implements OnChanges {
    @Input() broadcaster: Broadcaster;

    broadcasterName: string;
    broadcasterClusterName: string;
    broadcasterClusterDnsPrefix: string;
    clusterPageWithBroadcasterSelectedUrl: string[] | null;
    cpuStatus: Status;
    memoryStatus: Status;
    hddStatus: Status;

    ngOnChanges(changes: SimpleChanges): void {
        const { firstChange, previousValue, currentValue } = changes.broadcaster;
        const isChanged = firstChange || !_.isEqual(previousValue, currentValue);
        if (isChanged) {
            this.broadcasterName = this.broadcaster?.name;
            this.broadcasterClusterName = this.broadcaster?.broadcaster_cluster?.name;
            this.broadcasterClusterDnsPrefix = this.broadcaster?.broadcaster_cluster?.dns_prefix;
            this.setBroadcasterUrl();
            this.setCpuStatus();
            this.setMemoryStatus();
            this.setHddStatus();
        }
    }

    setBroadcasterUrl() {
        if (!this.broadcasterName || !this.broadcasterClusterDnsPrefix) {
            return (this.clusterPageWithBroadcasterSelectedUrl = null);
        }
        this.clusterPageWithBroadcasterSelectedUrl = ["/"].concat(
            urlBuilder.getRegularBroadcasterUrl(
                this.broadcaster.broadcaster_cluster_id,
                this.broadcaster.id,
                this.broadcaster.name
            )
        );
    }

    setCpuStatus() {
        if (this.broadcaster?.status?.cpu == null) {
            return (this.cpuStatus = null);
        }
        if (this.broadcaster.status.cpu < 50) {
            this.cpuStatus = Status.GOOD;
        } else if (this.broadcaster.status.cpu < 75) {
            this.cpuStatus = Status.WARNING;
        } else {
            this.cpuStatus = Status.BAD;
        }
    }

    setMemoryStatus() {
        if (this.broadcaster?.status?.ram == null) {
            return (this.memoryStatus = null);
        }
        this.memoryStatus = Status.DISABLE;

        if (this.broadcaster.status.ram == null) {
            return (this.memoryStatus = null);
        }
        if (this.broadcaster.status.ram < 50) {
            this.memoryStatus = Status.GOOD;
        } else if (this.broadcaster.status?.ram < 75) {
            this.memoryStatus = Status.WARNING;
        } else {
            this.memoryStatus = Status.BAD;
        }
    }

    setHddStatus() {
        const hdd = Math.max(this.broadcaster?.status?.disk_space, this.broadcaster?.status?.disk_space_install);
        if (isNaN(hdd)) {
            return (this.hddStatus = null);
        }
        if (hdd < 90) {
            this.hddStatus = Status.GOOD;
        } else if (hdd < 95) {
            this.hddStatus = Status.WARNING;
        } else {
            this.hddStatus = Status.BAD;
        }
    }
}
