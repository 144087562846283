<div class="h-100 d-flex flex-column justify-content-between">
    <div class="d-flex flex-column">
        <!-- First row -->
        <div class="d-flex flex-row justify-content-between">
            <h5 class="ellipsis me-1">
                <span *ngIf="name">{{ name }}</span>
                <span *ngIf="!name && !source?.name">{{ titlePlaceholder }}</span>
                <zx-source
                    *ngIf="!name && source && source.name"
                    [model]="source"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="false"
                    [showTag]="false"
                ></zx-source>
            </h5>
            <fa-icon
                *ngIf="icon && icon !== 'external-link' && (name || source?.name)"
                [icon]="icon"
                size="sm"
                style="cursor: pointer; margin-top: -3px"
                (click)="iconClicked.emit()"
                class="me-1"
                title="{{ iconTitle }}"
            ></fa-icon>
        </div>
        <!-- Second row -->
        <small class="d-flex flex-row justify-content-between" *ngIf="secondRow">
            <div>
                <span>{{ (priority === 0 ? "SLATE" : priority === 1 ? "SECONDARY" : "PREFERRED") | translate }}</span
                ><span *ngIf="source?.status?.is_active" class="ms-1">({{ "ACTIVE" | translate }})</span>
            </div>
            <div>
                <fa-icon *ngIf="locked" icon="lock" size="sm" class="me-1" title="{{ 'LOCKED' | translate }}"></fa-icon>
                <a *ngIf="icon === 'external-link'" [routerLink]="detailsUrl" target="_blank"><fa-icon [icon]="icon" size="sm"></fa-icon></a>
            </div>
        </small>
        <!-- Thumbnail -->
        <app-source-thumbnail
            [source]="source"
            [info]="false"
            [bordered]="false"
            [overlay]="overlay"
            [bitrateOverlay]="overlay"
            [allowLivePlay]="allowLivePlay"
        ></app-source-thumbnail>
        <!-- Buttons -->
        <div class="d-flex flex-row mt-2" *ngIf="source && (source?.webrtc_mode !== '' || (source?.view_url && canVLC))">
            <!-- Play -->
            <div class="form-group me-3 mb-0" *ngIf="source?.webrtc_mode !== ''">
                <div>
                    <a [routerLink]="['/' + urls.sources, source?.id | uId, source?.name, 'webrtc']" target="_blank" class="btn btn-play btn-primary"
                        ><fa-icon icon="play" size="xs" class="me-1"></fa-icon>{{ "WEBRTC" | translate }}</a
                    >
                </div>
            </div>
            <div class="form-group mb-0" *ngIf="source?.view_url && canVLC">
                <div>
                    <a href="/play/source/{{ source.playId }}" target="_blank" class="btn btn-play btn-primary"
                        ><fa-icon icon="play" size="xs" class="me-1"></fa-icon>{{ "VLC" | translate }}</a
                    >
                </div>
                <small>
                    ({{ "REQUIRES" | translate }} <a target="_blank" id="downloadVLC" href="http://www.videolan.org/vlc/">{{ "VLC" | translate }}</a>
                    {{ "WITH_ZIXI_PLUGIN" | translate }}
                    <a target="_blank" id="downloadWindows" href="https://portal.zixi.com/free/zixi_vlc_plugin-win-latest.zip">{{ "WINDOWS" | translate }}</a
                    >, <a target="_blank" id="downloadMac" href="https://portal.zixi.com/free/zixi_vlc_plugin-mac-latest.tar.gz">{{ "MAC" | translate }}</a
                    >)
                </small>
            </div>
        </div>
    </div>
</div>
