import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Constants } from "./../constants/constants";
import { SearchResult } from "./../models/search";

@Injectable({
    providedIn: "root"
})
export class SearchService {
    constructor(private http: HttpClient) {}

    async search(query: string): Promise<SearchResult> {
        const result = await this.http
            .get<{ result: SearchResult; success: boolean }>(Constants.apiUrl + Constants.apiUrls.search, {
                params: { q: query }
            })
            .toPromise();
        return result.result;
    }
}
