import { Injectable } from "@angular/core";
import { interval } from "rxjs";
import moment from "moment";
import { map, takeWhile } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class CountdownService {
    set(futureDate: Date) {
        const second = 1000;
        return interval(second).pipe(
            map(() => {
                return this.getCountdownTime(futureDate);
            }),
            takeWhile(countdownTime => countdownTime !== "00:00:00", true)
        );
    }

    private getCountdownTime(futureDate: Date): string {
        const timeDifference = moment(futureDate).diff(moment());
        const isCountdownEnd = timeDifference < 0;
        if (isCountdownEnd) {
            return "00:00:00";
        }

        let countdown = moment.utc(timeDifference).format("HH:mm:ss");
        const isMoreThanOneDay = timeDifference > 86400000;
        if (isMoreThanOneDay) {
            const duration = moment.duration(timeDifference, "milliseconds");
            const hours = Math.floor(duration.asHours());
            countdown = hours + countdown.slice(2);
        }

        return countdown;
    }
}
