import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, ReplaySubject } from "rxjs";
import { share } from "rxjs/operators";
import * as _ from "lodash";

import { TranslateService } from "@ngx-translate/core";
import { Constants } from "./../constants/constants";
import { StatusSummary, OpenIssue } from "./../models/status";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root"
})
export class StatusService {
    status: Observable<StatusSummary>;
    private status$: ReplaySubject<StatusSummary>;
    private dataStore: {
        status: StatusSummary;
    };

    private lastStatusRefresh: number;

    constructor(private http: HttpClient, private translate: TranslateService, private authService: AuthService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            status: null
        };

        this.lastStatusRefresh = null;

        this.status$ = new ReplaySubject(1) as ReplaySubject<StatusSummary>;
        this.status = this.status$.asObservable();
    }

    refreshStatus(force?: boolean): Observable<StatusSummary> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastStatusRefresh <= 60000) return this.status;
        this.lastStatusRefresh = _.now();

        const status$ = this.http
            .get<{ success: boolean; result: StatusSummary }>(Constants.apiUrl + Constants.apiUrls.statuses)
            .pipe(share());

        status$.subscribe(
            data => {
                this.dataStore.status = data.result;
                this.status$.next(Object.assign({}, this.dataStore).status);
            },
            error => {
                this.lastStatusRefresh = undefined;
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_STATUS"), error);
            }
        );
        return this.status;
    }

    async getIssues(): Promise<OpenIssue[]> {
        const result = await this.http
            .get<{ result: OpenIssue[]; success: boolean }>(Constants.apiUrl + Constants.apiUrls.issues)
            .toPromise();
        return result.result;
    }
}
