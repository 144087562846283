<div class="page-container">
    <div class="page-content">
        <!-- Title Bar -->
        <div class="title-bar">
            <div class="title">
                <h1 data-zm="page-title" translate title="{{ '404_TITLE' | translate }}">404_TITLE</h1>
            </div>
        </div>
        <div class="content-area">
            <div class="container-fluid">
                <div class="row">
                    <div class="col text-center">
                        <div class="big-text">404</div>
                        <h2>{{ "404_MESSAGE" | translate }}</h2>
                        <div>
                            <button (click)="gotoDashboard()" type="button" class="btn btn-primary">
                                <fa-icon icon="tachometer" size="sm"></fa-icon>
                                {{ "DASHBOARD" | translate }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
