import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Constants } from "../../constants/constants";

@Injectable({
    providedIn: "root"
})
export class ResetPasswordService {
    constructor(private http: HttpClient) {}

    reset(password: string, token: string) {
        const postData = {
            password,
            token
        };

        const options = {
            headers: {
                dns_prefix: location.hostname === "localhost" ? "zixi" : location.hostname.split(".")[0]
            }
        };

        return this.http.post(Constants.apiUrl + Constants.apiUrls.reset, postData, options);
    }
}
