import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { FontAwesome } from "./../../modules/font-awesome/font-awesome.module";

import { SharedModule } from "./../../modules/shared/shared.module";
import { CommonModule } from "@angular/common";

import { BroadcastersService } from "./broadcasters.service";
import { BroadcasterConfigHelpDialogComponent } from "./../shared/modals/broadcaster-config-help-dialog/broadcaster-config-help-dialog.component";
@NgModule({
    imports: [CommonModule, SharedModule, FormsModule, NgbModule, NgSelectModule, FontAwesome],
    declarations: [BroadcasterConfigHelpDialogComponent],
    providers: [BroadcastersService]
})
export class BroadcastersModule {}
