<ng-container *ngIf="targetsQuantity">
    <div *ngIf="targetsQuantity > 3; else targetDetailsView" class="h-100 p-1 d-flex flex-column justify-content-between">
        <div class="d-flex flex-row justify-content-between">
            <h5>{{ "TARGETS" | translate }}</h5>
            <a [routerLink]="deliveryChannelUrl" target="_blank"><fa-icon icon="external-link" size="sm"></fa-icon></a>
        </div>
        <div class="d-flex flex-wrap flex-row justify-content-between">
            <ng-container *ngFor="let targetsStatus of targetsStatuses | keyvalue">
                <app-circle-status-indicator [title]="targetsStatus.value" [status]="targetsStatus.key"></app-circle-status-indicator>
            </ng-container>
        </div>
    </div>
    <ng-template #targetDetailsView>
        <div class="h-100 d-flex flex-column">
            <ng-container *ngFor="let target of targetsDetails.targets; let index = index">
                <div class="target col-sm mb-2 p-2 d-flex flex-column justify-content-between">
                    <div class="d-flex flex-row justify-content-between">
                        <h5 class="ellipsis">{{ target.target.name }}</h5>
                        <a [routerLink]="targetsUrls[index]" target="_blank"><fa-icon icon="external-link" size="sm"></fa-icon></a>
                    </div>
                    <div class="d-flex flex-wrap flex-row justify-content-between">
                        <zx-status-full
                            [model]="target.target"
                            [text]="target.status"
                            [channelDisabled]="!targetsDetails.deliveryChannel.is_enabled"
                        ></zx-status-full>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-template>
</ng-container>
<ng-container *ngIf="!targetsQuantity">
    <p>{{ "NONE" | translate }}</p>
</ng-container>
