import { Component, ElementRef, AfterViewInit, HostListener, ViewChildren, QueryList } from "@angular/core";
import { ResizeService } from "./../../../services/resize.service";
import { SCREEN_SIZE } from "./../../../models/screen-size.enum";

@Component({
    selector: "app-size-detector",
    templateUrl: "./size-detector.component.html"
})
export class SizeDetectorComponent implements AfterViewInit {
    @ViewChildren("sizeDetectorDiv") sizeElements: QueryList<ElementRef>;

    prefix = "is-";
    sizes = [
        {
            id: SCREEN_SIZE.XXS,
            name: "xxs",
            css: `d-block d-xs-none`
        },
        {
            id: SCREEN_SIZE.XS,
            name: "xs",
            css: `d-block d-xs-block d-sm-none`
        },
        {
            id: SCREEN_SIZE.SM,
            name: "sm",
            css: `d-none d-sm-block d-md-none`
        },
        {
            id: SCREEN_SIZE.MD,
            name: "md",
            css: `d-none d-md-block d-lg-none`
        },
        {
            id: SCREEN_SIZE.LG,
            name: "lg",
            css: `d-none d-lg-block d-xl-none`
        },
        {
            id: SCREEN_SIZE.XL,
            name: "xl",
            css: `d-none d-xl-block d-xxl-none`
        },
        {
            id: SCREEN_SIZE.XXL,
            name: "xxl",
            css: `d-none d-xxl-block d-xxxl-none`
        },
        {
            id: SCREEN_SIZE.XXXL,
            name: "xxxl",
            css: `d-none d-xxxl-block`
        }
    ];

    constructor(private rs: ResizeService) {}

    @HostListener("window:resize", [])
    private onResize() {
        this.detectScreenSize();
    }

    ngAfterViewInit() {
        this.detectScreenSize();
    }

    private detectScreenSize() {
        // super ugly hack to detect whether css finished applying properly
        // turns out that before it does all the elements report display block
        if (
            this.sizeElements.reduce(
                (allBlock, el) => window.getComputedStyle(el.nativeElement).display === "block" && allBlock,
                true
            )
        ) {
            setTimeout(() => this.detectScreenSize(), 10);
            return;
        }

        const currentSizeElement = this.sizeElements.find(
            el => window.getComputedStyle(el.nativeElement).display !== "none"
        );
        this.rs.setCurrentSize(currentSizeElement.nativeElement.id);
    }
}
