import { OnInit, ViewChild } from "@angular/core";
import { Component } from "@angular/core";
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import _ from "lodash";
import { Constants } from "src/app/constants/constants";
import { LiveEventsService } from "../../live-events.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { LiveEvent, LiveEventStage } from "../events/liveevent";
import { TourService } from "ngx-ui-tour-md-menu";
import { TourSteps } from "src/app/constants/tour-steps";
import { LiveEventStageTableComponent } from "../../components/live-event-stage-table/live-event-stage-table.component";
import { LiveEventClipTableComponent } from "../../components/live-event-clip-table/live-event-clip-table.component";
import { SharedService } from "src/app/services/shared.service";

export type Stage = { name: string; duration: number; force_channel_slate: boolean; clip_id: string | null };

@Component({
    selector: "app-live-event-form",
    templateUrl: "./live-event-form.component.html",
    styleUrls: ["./live-event-form.component.scss"]
})
export class LiveEventFormComponent implements OnInit {
    @ViewChild(LiveEventStageTableComponent) stagesTable: LiveEventStageTableComponent;
    @ViewChild(LiveEventClipTableComponent) clipsTable: LiveEventClipTableComponent;

    nameControl = new UntypedFormControl("", [Validators.required, Validators.minLength(2)]);
    tagsControl = new UntypedFormControl([], [Validators.required]);
    failoverChannelsControl = new UntypedFormControl([], [Validators.required]);
    startTimeControl = new UntypedFormControl(null, [Validators.required]);

    selectModeControl = new UntypedFormControl("Auto", []);
    disableChannelControl = new FormControl<Boolean>(true);

    form = new UntypedFormGroup({
        name: this.nameControl,
        tags: this.tagsControl,
        failoverChannels: this.failoverChannelsControl,
        startTime: this.startTimeControl,
        selectMode: this.selectModeControl,
        disableChannel: this.disableChannelControl
    });

    loading = true;
    isEdit = false;
    isClone = false;
    options = ["Auto", "Manual"];
    exitUrl = Constants.urls.liveevents;
    isSubmitted = false;
    isInvalidSubmit = false;
    saving = false;
    action = "create";
    liveEventId: number;
    liveEvent: LiveEvent;
    stages: Stage[] = [{ name: null, duration: null, force_channel_slate: false, clip_id: null }];
    clips: { name: string; url: string }[] = [];

    private tourSteps = TourSteps.eventForm;

    constructor(
        private liveEventsService: LiveEventsService,
        private router: Router,
        private route: ActivatedRoute,
        private mixpanelService: MixpanelService,
        public tourService: TourService,
        private sharedService: SharedService
    ) {
        this.selectModeControl.valueChanges.subscribe(stagingMode => {
            if (stagingMode === "Manual") {
                this.startTimeControl.clearValidators();
            } else {
                this.startTimeControl.setValidators(Validators.required);
            }
            this.startTimeControl.updateValueAndValidity();
        });

        this.route.paramMap.subscribe(params => {
            this.liveEventId = parseInt(params.get("id"), 10);
            this.action = this.router.url.split("/")[3];
            this.isEdit = this.action === "edit";
            this.isClone = this.action === "clone";

            if (this.liveEventId) {
                this.liveEvent = this.liveEventsService.getCachedLiveEvent(this.liveEventId);
                if (!this.isClone) this.nameControl.setValue(this.liveEvent.name);
                this.failoverChannelsControl.setValue(this.liveEvent.failoverChannels);
                this.tagsControl.setValue(this.liveEvent.resourceTags);
                if (!this.isClone) this.startTimeControl.setValue(this.liveEvent.start_time);

                this.clips = [...this.liveEvent.clips];

                this.stages = this.liveEvent.stages.map(this.convertClipIdToName);

                this.selectModeControl.setValue(
                    this.liveEvent.staging_mode.charAt(0).toUpperCase() + this.liveEvent.staging_mode.slice(1)
                );
                this.disableChannelControl.setValue(this.liveEvent.disable_channel_after_done);
            }
        });

        this.loading = false;
    }

    async ngOnInit() {
        if (!this.isEdit && !this.isClone) {
            this.tourService.initialize(this.tourSteps);
            return;
        }
    }

    stagesChanged(stages) {
        this.stages = stages;
    }

    async onSubmit() {
        this.saving = true;
        const formControls = {
            ...this.form.controls,
            ...this.stagesTable.form.controls,
            ...this.clipsTable.form.controls
        };
        this.isInvalidSubmit = false;
        for (const field in formControls) {
            const control: AbstractControl = formControls[field];
            if (control.errors != null) {
                this.isInvalidSubmit = true;
                this.saving = false;
                return;
            }
        }

        const model = {
            name: formControls.name.value,
            resource_tag_ids: _.map(formControls.tags.value, "id"),
            failover_channel_ids: _.map(formControls.failoverChannels.value, "id"),
            start_time: formControls.startTime.value,
            staging_mode: formControls.selectMode.value.toLowerCase(),
            disable_channel_after_done: !!formControls.disableChannel.value,
            stages: this.stages,
            clips: this.clips.filter(clip => !!clip.name && !!clip.url)
        };

        if (this.isEdit) {
            const objects = {
                resource_tag_ids: { objectsKey: "resourceTags", valuePath: "id" },
                failover_channel_ids: { objectsKey: "failoverChannels", valuePath: "id" }
            };
            const diff = this.sharedService.getZixiObjDiff(
                {
                    ...model
                },
                {
                    ...this.liveEvent,
                    disable_channel_after_done: !!this.liveEvent.disable_channel_after_done,
                    stages: this.liveEvent.stages.map(this.convertClipIdToName)
                },
                null,
                objects
            );
            if (this.sharedService.isEmptyObject(diff)) {
                this.saving = false;
                return this.router.navigate([this.exitUrl]);
            }
            if (!("stages" in diff || "clips" in diff)) {
                delete model.stages;
                delete model.clips;
            }
        }

        let result;
        if (this.isEdit) {
            result = await this.liveEventsService.updateLiveEvent(this.liveEvent.id, model);
        } else {
            result = await this.liveEventsService.addLiveEvent(model);
        }
        if (result) {
            this.mixpanelService.sendEvent(`${this.action} live-event`);
            this.saving = false;
            this.router.navigate([this.exitUrl]);
        }
        this.saving = false;
    }
    disableChange() {
        this.disableChannelControl.setValue(!this.disableChannelControl.value);
    }

    clipChanged(clips: { name: string; url: string }[]) {
        this.clips = [...clips];

        // sync deleted clips on stages table
        for (const stage of this.stages) {
            if (!this.clips.map(clip => clip.name).includes(stage.clip_id)) {
                stage.clip_id = null;
            }
        }
    }

    private convertClipIdToName = (stage: LiveEventStage) => ({
        // convert clip_id to a url
        ...stage,
        clip_id:
            typeof stage.clip_id === "number"
                ? this.liveEvent.clips.find(clip => clip.id === (stage.clip_id as unknown as number)).name
                : stage.clip_id
    });
}
