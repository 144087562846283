import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Constants } from "../../constants/constants";
import { APIResponse } from "src/app/models/shared";

@Injectable({
    providedIn: "root"
})
export class SetPasswordService {
    constructor(private http: HttpClient) {}

    set(password: string) {
        return this.http.put<APIResponse<unknown>>(Constants.apiUrl + Constants.apiUrls.self + "/set_password", {
            password
        });
    }
}
