import { Component } from "@angular/core";
import { Announcements } from "@zixi/models";
import { AnnouncementsService } from "../../services/announcements.service";

@Component({
    selector: "app-critical-announcements",
    templateUrl: "./critical-announcements.component.html",
    providers: [AnnouncementsService, { provide: "isCriticalAnnouncements", useValue: true }]
})
export class CriticalAnnouncementsComponent {
    criticalAnnouncements$ = this.announcementsService.announcements$;

    constructor(private announcementsService: AnnouncementsService) {}

    dismiss(announcement: Announcements) {
        this.announcementsService.dismissAnnouncement(announcement);
    }
}
